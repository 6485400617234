<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'role-links' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'imoprt-file' }" class="root"> 
                Admin Import
            </router-link>
            <span class="child">/ Import Employee </span>
        </v-row>
        <br />
         
        <v-card class="card">
            <v-card-title class="heading">Import Employee
             <v-btn
          color="white"
          v-if="!isLoading"
          class="px-5 mx-5 button"
          v-on="on"
          @click="synchLearner()"
          style="margin: 2px; float: right"
          >synchronize Employee from ERP</v-btn
        >
      
          <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
            <v-card width="400" height="100">
              <center class="pa-5">
                Data Importing Form ERP ..<br />
                Please Wait It will Take Time..
              </center>
            </v-card>
            </v-row>
          </v-dialog>
            </v-card-title>
            <!-- <v-card class="box">
                    <div class="flex">
                    <div></div>
                        <div>
                            <a href="https://easypariksha.s3.ap-south-1.amazonaws.com/import_excel_csv_file/Import_Instructor.csv">
                            <v-btn :loading="loading3" :disabled="loading3" color="success" class="ma-2 white--text downbtn" @click="loader = 'loading3'">
                                <v-icon left dark>mdi-cloud-download</v-icon>Download import CSV format
                            </v-btn>
                            </a>
                        </div>
                    </div>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" lg="4" sm="12">
                                <v-file-input dense v-model="file" :hint="file != null? '':'Please Select File'" persistent-hint color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size> </v-file-input>
                            </v-col>
                            <v-col cols="4"> </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <center>
                                    <v-btn @click="importCsv" :loading="output_load" class="button">
                                        Upload
                                        <v-icon right>mdi-cloud-upload</v-icon>
                                    </v-btn>
                                </center>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card> -->

            <div class="add-section">
                <v-row class="justify-content-between set-btn">
                    <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_emp_member_csv.csv">
                        <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV Template</v-btn></a>
                    </div>
                    <div>
                        <v-btn class="button mb-2" @click="openDialog"> <v-icon left>mdi-account-plus</v-icon>Add New Employee</v-btn>
                    </div>
                </v-row>
                <p class="file-warn">NOTE: *File should be in CSV format.</p>

                <v-row class="justify-content-center">
                    <v-col lg="5" sm="12" md="5" class="import-box">
                        <label class="label">IMPORT CSV</label>
                        <v-file-input dense v-model="file" :hint="file != null? '':'Please Select File'" persistent-hint color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size>
                        </v-file-input>
                        <v-btn @click="importCsv" :loading="output_load" class="button">
                            Upload
                            <v-icon right>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <template>
            <v-simple-table v-if="failList != null">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Employee Code
                            </th>
                            <th class="text-left">Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in failList" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td style="color: red;">{{ item.reason }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="emplist" sort-by class="elevation-1 nowrap" :search="search" :loading="output_load">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <download-excel class="mar" :data="emplist" :fields="export_table_headers" worksheet="statuslist" name="Instructor Data.xls">
                               <a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_emp_member_csv.csv">
                                <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon> </v-btn></a>
                            </download-excel>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                            <span style="width: 20px;"></span>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive==true">
                            <v-icon class="mr-2" color="success" @click="toggle(item)">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2" color="error" @click="toggle(item)">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <div>
                           
                                <v-icon size="18" @click="editDialogFunction(item)">mdi mdi-pencil-box</v-icon>
                           
                        </div>
                    </template>
                </v-data-table>
            </template>
            
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card>
                    <v-card-title class="heading">
                        <span>Add New Employee</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="employee_code" outlined dense label="Employee Code" :hint="employee_code!=null?'':'Please enter employee cose'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="empname" outlined dense label="Name" :hint="name!= null?'':'Please enter employee name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="email" label="Email" outlined dense type="email" :hint="email!= null?'':'Please enter employee email'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="mobile_number" outlined dense type="number" counter="10" label="contactno" :hint="mobileno!=null?'':'Please enter mobile number.'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="address" outlined dense label="Address" :hint="address!=null?'':'Please enter address'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                <v-autocomplete      class="text"    item-text="name"
                    item-value="id" :items="programtypelist" label="Program" :hint="pt!=null?'':'Please select Program'" v-model="pt" dense outlined></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="city"  outlined dense label="City" :hint="city!=null?'':'Please enter city name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="state" outlined dense label="State" :hint="state!=null?'':'Please enter state name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="country" outlined dense label="Country" :hint="country!=null?'':'Please enter country name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="pin" outlined denselabel="Pin" :hint="pin!=null?'':'Please enter pin code'" persistent-hint></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <small style="color: red;">*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" text @click="dialog = false">Close</v-btn>
                        <v-btn class="button" text @click="addInst">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="editDialog" persistent max-width="600px">
                <v-card>
                    <v-card-title class="heading">
                        <span>Edit Employee Details</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.employee_code" label="Employee Code" :hint="editedItem.employee_code!=null?'':'Please enter employee cose'" persistent-hint disabled></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.empname" label="Name" :hint="editedItem.name!= null?'':'Please enter employee name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.email" label="Email" type="email" :hint="editedItem.email!= null?'':'Please enter employee email'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.mobile_number" type="number" counter="10" label="contactno" :hint="editedItem.mobileno!=null?'':'Please enter mobile number.'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.address" label="Address" :hint="editedItem.address!=null?'':'Please enter address'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                <v-autocomplete      class="text"    item-text="name"
                    item-value="id" :items="programtypelist" label="Program" :hint="editedItem.pt!=null?'':'Please select Program'" v-model="editedItem.pt" dense outlined></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.city" label="City" :hint="editedItem.city!=null?'':'Please enter city name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.state" label="State" :hint="editedItem.state!=null?'':'Please enter state name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.country" label="Country" :hint="editedItem.country!=null?'':'Please enter country name'" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="editedItem.pin" label="Pin" :hint="editedItem.pin!=null?'':'Please enter pin code'" persistent-hint></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" text @click="editDialog = false">Close</v-btn>
                        <v-btn class="button" text @click="saveEdited">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>

       
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        /*
      Defines the data used by the component
    */
        data() {
            return {
                emplist: [],
                file: null,
                statuslist: "",
                load: false,
                loading3: false,
                search: "",
                failList: null,
                dialog: false,
                editDialog: false,
                employee_code: null,
                empname: null,
                email: null,
                mobile_number: null,
                address: null,
                city: null,
                state: null,
                country: null,
                pin: null,
                pt:null,
                programtypelist:null,
                snackbar_msg: "",
                color: "",
                snackbar: false,
                InstRecord: "",
                org: null,
                 isLoading: false,
                export_table_headers: {
                    "Sr.No.": "sr_no",
                     "Employee Code":"employee_code",
                    "Instructor Name": "empname",
                    "Email Id": "email",
                     "Program":"program"
                },
                headers: [
                    {
                        text: "Sr.no",
                        value: "sr_no",
                    },
                    {
                        text: "Employee Code",
                        value: "employee_code",
                    },
                    {
                        text: "Name",
                        value: "empname",
                    },
                    {
                        text: "Email Id",
                        value: "email",
                    },
                    {
                        text: "Contact No",
                        value: "mobile_number",
                    },
                    {
                        text: "Address",
                        value: "address",
                    },
                    {
                        text: "City",
                        value: "city",
                    },
                    {
                        text: "State",
                        value: "state",
                    },
                    {
                        text: "Country",
                        value: "country",
                    },
                    {
                        text: "Pin",
                        value: "pin",
                    },
                        {
                        text: "Program",
                        value: "program",
                    },
                    // {
                    //     text: "On/Off",
                    //     value: "isactive"
                    // },
                    {
                        text: "Action",
                        value: "edit",
                    },
                ],
                editedItem: {
                    id: "",
                    employee_code: "",
                    empname: "",
                    email: "",
                    mobile_number: "",
                    address: "",
                    city: "",
                    state: "",
                    country: "",
                    pin: "",
                    pt:"",
                },
                output_load: false,
            };
        },

        mounted() {
            this.onLoad();
        },
        methods: {
            synchLearner() {
      this.isLoading = true;
      axios
        .post("/ImportData/synchemployee")
        .then((res) => {
          if (res.data.status.code == "SUCCESS") {
            this.showSnackbar(
              "#4caf50",
              "Learner Synchronized Successfully..!"
            );
            this.isLoading = false;
             this.onLoad();
          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Error While Synchronization");
            this.isLoading = false;
          }
        })
        .catch((error) => {
          window.console.log(error);
          this.isLoading = false;
        });
    },
            onLoad() {
                axios
                    .get("/ImportData/importEmployeeData")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.emplist = res.data.emplist;
                            this.programtypelist =res.data.programtypelist
                            this.load = true;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar on failure
                        window.console.log(error);
                    })
                    .finally(() => {
                        //close loader
                    });
            },
            editDialogFunction(val) {
                this.editedItem = Object.assign({}, val);
                this.editDialog = true;
            },
            saveEdited() {
                console.log("save edited item", this.editedItem);
                axios
                    .post("/ImportData/editInstructor", this.editedItem)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            // this.statuslist.push(this.editedItem);
                            this.editDialog = false;
                            this.showSnackbar("#4caf50", "Instructor details updated!!!"); // show snackbar on success
                            this.onLoad();
                            // this.statuslist = res.data.statuslist;
                        } else {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar on failure
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar on failure
                        window.console.log(error);
                    });
            },
            openDialog() {
                this.dialog = true;
            },
            addInst() {
                if (this.employee_code == null) {
                    alert("* marked field is required");
                } else {
                    this.dialog = false;
                    const data = {
                        employee_code: this.employee_code,
                        empname: this.empname,
                        email: this.email,
                        mobile_number: this.mobile_number,
                        address: this.address,
                        country: this.country,
                        city: this.city,
                        state: this.state,
                        pin: this.pin,
                        pt:this.pt
                    };
                    //console.log(data);
                    axios
                        .post("/ImportData/addEmployee", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                //console.log("res data:::: " + res.data.statuslist);
                                // this.statuslist = res.data.statuslist;
                                this.load = true;
                                this.showSnackbar("#4caf50", "Instructor added successfully..."); // show snackbar on success
                            } else {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar on failure
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                }
            },
            importCsv() {
                let formData = new FormData();
                if (this.file !== null) {
                   if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                       this.showSnackbar("red", "File must be in CSV format Only.!");
                   } else{
                        this.fileStatus = true;
                        formData.append("file", this.file);
                        axios
                            .post("ImportData/importEmployee", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => {
                                this.failList = res.data.failList;
                                if (res.data.status == "200") {
                                    this.showSnackbar("#4caf50", "Employee added successfully...");
                                    this.onLoad();
                                }
                                this.file = null;
                                //this.onLoad();
                            })
                            .catch(function () {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            })
                            .finally(() => {
                                this.output_load = false;
                            });
                   } 
                } else {
                    this.fileStatus = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            checkuser() {
                alert("check user name");
            },
            checkExtention() {
                if (this.file != null) {
                    var file = this.file.name;
                    var ext = file.split(".").pop();
                    if (ext == "csv") {
                        return true;
                    } else {
                        // console.log("false")
                        // alert("Upload only CSV file!");
                        return false;
                    }
                } else {
                    return false;
                }
            },
        },
    };
</script>

<style scoped>
    .downbtn {
        float: right;
    }
    .add-section {
        display: block;
    }
    .box {
        background-color: #add8e630;
        margin: 8px !important;
        padding: 8px;
    }
    .flex {
        display: flex;
        justify-content: space-between;
    }
    @media only screen and (max-width: 600px) {
        .flex {
            display: block;
        }
    }
    .btn {
        margin: 1rem;
        padding-top: 1rem;
    }
</style>
